import {
    hydraMember,
    hydraTotalItems,
    getResourceItem,
    RowData,
    SimpleRowData,
    DataQueryFunction,
    queryClient,
    createQueryFn,
    RequestError,
    DataQueryOptions,
    Collection
} from 'lib/sdk';
import {
    ColDef,
    ColGroupDef,
    GridApi,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    IServerSideGetRowsRequest,
    IStatusPanelComp
} from '@ag-grid-community/core';
import { CustomOptions, FilterModel, SortModel } from './config';
import { UrlReplacementString } from '../index';
import { parseISO } from 'date-fns';
import { FilterTabsMap } from './DatatableProps';
import { isEqual } from 'lodash';

interface SortModelItem {
    colId: string;
    sort: string;
}

const hydraUnfiltered: string = 'hydra:unfilteredItems';

type DataSourceUrl = string;
type DataSourceQueryFnArgs = Record<string, unknown>;
type DataSourceQueryFn<TData> = DataQueryFunction<any, Collection<TData>>;
type DataSourceQueryFnWithArgs<TData> = [DataSourceQueryFn<TData>, DataSourceQueryFnArgs];
type DataSourceType<TData> = DataSourceUrl | DataSourceQueryFn<TData> | DataSourceQueryFnWithArgs<TData>;

/**
 * Server-side datasource for ag-grid server model.
 */
class ServerSideDataSource<TData> implements IServerSideDatasource {
    protected dataSource: DataSourceType<TData>;
    protected api: GridApi;
    protected app: string;
    protected customOptions: CustomOptions;
    protected lastRow: number | null;
    protected firstLoad: boolean = true;
    protected totalRecords: number = 0;
    protected filteredTotal: number = 0;
    protected defaultFilter?: FilterTabsMap;
    protected filterBy?: string;
    protected columnDefs?: (ColDef | ColGroupDef)[] | undefined;
    // Allows the tree view to have a initial id to draw the data from
    protected defaultId: string | number;
    protected currentUrl: string;

    /**
     * ServerSideDataSource constructor.
     *
     * @param {DataSourceType}  dataSource
     * @param {GridApi}         api
     * @param {String}          app
     * @param {Object}          customOptions
     * @param {Number}          lastRow
     * @param {string}          defaultFilter
     * @param {string}          filterBy
     * @param {Array}           columnDefs
     * @param {String|Number}   defaultId
     */
    constructor(
        dataSource: DataSourceType<TData>,
        app: string,
        api: GridApi,
        customOptions: CustomOptions,
        lastRow: number | null,
        defaultFilter?: FilterTabsMap,
        filterBy?: string,
        columnDefs?: (ColDef | ColGroupDef)[] | undefined,
        defaultId?: string | number
    ) {
        this.dataSource = dataSource;
        this.api = api;
        this.app = app;
        this.customOptions = customOptions;
        this.lastRow = lastRow;
        this.defaultFilter = defaultFilter;
        this.filterBy = filterBy;
        this.columnDefs = columnDefs;
        this.defaultId = defaultId ?? '';
        this.currentUrl = typeof dataSource === 'string' ? dataSource : '#';
    }

    /**
     * Get the filter URI to append to the export
     *
     * @param {object} filterModel
     *
     * @return {String}
     */
    getFilterUri(filterModel: FilterModel): string {
        // look for a custom filterModel
        if (this.customOptions.filterModel !== undefined) {
            const customFilter: FilterModel = this.customOptions.filterModel;
            const customFilterModel: FilterModel = {};

            // loop through the filterModel and replace with customFields if they exist
            let properties: Record<string, unknown>;
            for (let field in filterModel) {
                properties = filterModel[field];

                // If the fieldName is specified using '0.fieldName', remove '0.' for filtering
                if (field.startsWith('0.')) {
                    field = field.substr(2, field.length - 2);
                }

                // check if this field has custom filter fields, add them if so
                if (customFilter[field] !== undefined) {
                    customFilter[field].fields?.forEach((customField) => (customFilterModel[customField] = properties));

                    // add the regular field
                } else {
                    customFilterModel[field] = properties;
                }
            }
            filterModel = customFilterModel;
        }

        let filterUri: string = '';
        if (this.filterBy && this.defaultFilter && !isEqual(this.defaultFilter, { name: 'all' })) {
            filterUri += '&required[' + this.filterBy + ']=' + this.defaultFilter.value;
        }

        for (const fieldName in filterModel) {
            let filter: string = filterModel[fieldName].filter ?? '';

            // If the fieldName is specified using '0.fieldName', remove '0.' for filtering
            let uriFilterName: string = fieldName;
            if (fieldName.startsWith('0.')) {
                uriFilterName = fieldName.substr(2, fieldName.length - 2);
            }

            // If its a date object then set range for an entire day
            if (filterModel[fieldName].filterType === 'date') {
                filter = filterModel[fieldName].dateFrom ?? '';

                // Set start date
                const dateObj: Date = parseISO(filter);
                let month: number = dateObj.getMonth() + 1;
                const dateFrom: string = month + '/' + dateObj.getDate() + '/' + dateObj.getFullYear();

                // Set end date
                dateObj.setHours(23);
                dateObj.setMinutes(59);
                dateObj.setSeconds(59);
                month = dateObj.getMonth() + 1;
                const dateTo: string =
                    month +
                    '/' +
                    dateObj.getDate() +
                    '/' +
                    dateObj.getFullYear() +
                    ' ' +
                    dateObj.getHours() +
                    ':' +
                    dateObj.getMinutes() +
                    ':' +
                    dateObj.getSeconds();

                // Add range to uri
                filterUri += '&' + uriFilterName + '[after]=' + encodeURIComponent(dateFrom);
                filterUri += '&' + uriFilterName + '[before]=' + encodeURIComponent(dateTo);
            } else if (filterModel[fieldName].filterType === 'set') {
                filter = filterModel[fieldName].values?.join(',') ?? '';
                filterUri += '&' + uriFilterName + '=' + encodeURIComponent(filter);
            } else if (filterModel[fieldName].filterType === 'number') {
                // Handle number/range filters to send to API Platform
                let operator: string = '';
                switch (filterModel[fieldName].type) {
                    case 'equals':
                        filterUri +=
                            '&' +
                            uriFilterName +
                            '[between]=' +
                            encodeURIComponent(filter) +
                            '..' +
                            encodeURIComponent(filter);
                        continue;
                    case 'lessThan':
                        operator = 'lt';
                        break;
                    case 'greaterThan':
                        operator = 'gt';
                        break;
                    case 'lessThanOrEqual':
                        operator = 'lte';
                        break;
                    case 'greaterThanOrEqual':
                        operator = 'gte';
                        break;
                    case 'inRange':
                        filterUri +=
                            '&' +
                            uriFilterName +
                            '[between]=' +
                            encodeURIComponent(filter) +
                            '..' +
                            encodeURIComponent(filterModel[fieldName].filterTo ?? '');
                        continue;
                    default:
                        break;
                }

                filterUri += '&' + uriFilterName + '[' + operator + ']=' + encodeURIComponent(filter);
            } else {
                filterUri += '&' + uriFilterName + '=' + encodeURIComponent(filter);
            }
        }

        return filterUri;
    }

    /**
     * Get the sort URI
     *
     * @param {SortModelItem[]} sortModel
     *
     * @return {String}
     */
    getSortUri(sortModel: SortModelItem[]): string {
        let sortUri: string = '';
        if (sortModel.length === 0) {
            return sortUri;
        }

        // look for a custom sortModel
        if (this.customOptions.sortModel !== undefined) {
            const customSort: SortModel = this.customOptions.sortModel;
            const customSortModel: SortModelItem[] = [];
            sortModel.forEach((column) => {
                if (column.colId.startsWith('0.')) {
                    column.colId = column.colId.substr(2, column.colId.length - 2);
                }
                // check if this field has custom sort fields, add them if so
                if (customSort[column.colId] !== undefined) {
                    customSort[column.colId].fields.forEach((customField) => {
                        customSortModel.push({ colId: customField, sort: column.sort });
                    });

                    // add the regular field
                } else {
                    customSortModel.push(column);
                }
            });
            sortModel = customSortModel;
        }

        let sort: SortModelItem;
        for (const i in sortModel) {
            sort = sortModel[i];
            // If the colId is specified using '0.fieldName', remove '0.' for filtering
            if (sort.colId.startsWith('0.')) {
                sort.colId = sort.colId.substr(2, sort.colId.length - 2);
            }
            sortUri += '&order[' + encodeURIComponent(sort.colId) + ']=' + encodeURIComponent(sort.sort);
        }

        return sortUri;
    }

    /**
     * Get the paging uri
     *
     * @param {Number} startRow
     * @param {Number} endRow
     *
     * @return {String}
     */
    getPagingUri(startRow: number, endRow: number): string {
        if (!startRow && !endRow) {
            return '';
        }

        const limit: number = endRow - startRow;

        // api-platform uses page number, calculate the page
        const page: number = Math.ceil(startRow / limit) + 1;

        return 'page=' + page + '&limit=' + limit;
    }

    /**
     * Returns the available data sources.
     *
     * @throw {Error} If no data source is defined.
     *
     * @return { sourceUrl: DataSourceUrl | undefined; queryFn?: DataSourceQueryFn<TData> | undefined; queryArgs?: DataSourceQueryFnArgs | undefined; }
     */
    getDataSource(): {
        sourceUrl: DataSourceUrl | undefined;
        queryFn?: DataSourceQueryFn<TData> | undefined;
        queryArgs?: DataSourceQueryFnArgs | undefined;
    } {
        let sourceUrl: DataSourceUrl | undefined = undefined;
        let queryFn: DataSourceQueryFn<TData> | undefined = undefined;
        let queryArgs: DataSourceQueryFnArgs | undefined = undefined;

        switch (true) {
            case typeof this.dataSource === 'function':
                queryFn = this.dataSource as DataSourceQueryFn<TData>;
                break;

            case typeof this.dataSource === 'string':
                sourceUrl = this.dataSource as DataSourceUrl;
                break;

            case Array.isArray(this.dataSource):
                queryFn = this.dataSource[0];
                queryArgs = this.dataSource[1];
                break;

            default:
                throw new Error('Invalid data source type');
        }

        return { sourceUrl, queryFn, queryArgs };
    }

    /**
     * Get the rows from the server
     *
     * @param {IServerSideGetRowsParams} getRowsParams
     *
     * @return Promise<void>
     */
    async getRows(getRowsParams: IServerSideGetRowsParams): Promise<void> {
        let originalUrl: string = '';
        let queryOptions: DataQueryOptions<Collection<TData>, RequestError> | undefined;
        const { sourceUrl, queryFn, queryArgs } = this.getDataSource();

        if (sourceUrl) {
            originalUrl = sourceUrl;
        }

        if (queryFn) {
            queryOptions = queryFn(queryArgs ?? {}, queryClient);
            originalUrl = queryOptions.path;
        }

        let baseUrl: string = originalUrl;

        if (baseUrl.includes(UrlReplacementString)) {
            let strToReplace: string | number | null = this.defaultId;

            if (getRowsParams.parentNode.key !== null) {
                strToReplace = getRowsParams.parentNode.key;
            }

            baseUrl = baseUrl.replace(UrlReplacementString, String(strToReplace));
        }

        const request: IServerSideGetRowsRequest = getRowsParams.request;

        const sortUri: string = this.getSortUri(request.sortModel);
        const pagingUri: string = this.getPagingUri(request.startRow ?? 0, request.endRow ?? 1);
        const filterUri: string = this.getFilterUri(request.filterModel);

        if (originalUrl.indexOf('?') === -1) {
            baseUrl = baseUrl + '?datatable=true&';
        } else {
            baseUrl = baseUrl + '&datatable=true&';
        }

        const url: string = baseUrl + pagingUri + filterUri + sortUri;
        let resource: RowData<TData> | SimpleRowData<TData> | null = null;

        if (sourceUrl) {
            // otherwise get resource from api
            resource = await getResourceItem(url, this.app);
        }

        if (queryFn && queryOptions) {
            const { app, queryKey, path, queryString = {}, ...otherQueryOptions } = queryOptions;

            resource = await queryClient.fetchQuery<RowData<TData>, RequestError, RowData<TData>>(
                [...queryKey, pagingUri + filterUri + sortUri],
                createQueryFn(url, queryString, app, false),
                otherQueryOptions as Record<string, string | number> // Object with options for React Query.
            );
        }

        this.currentUrl = url;

        this.processRows(resource, getRowsParams, filterUri);
    }

    /**
     * Ajax callback to process the returned rows.
     *
     * @param {RowData}                  resource
     * @param {IServerSideGetRowsParams} getRowsParams
     * @param {String}                   filterUri
     *
     * @return void
     */
    processRows(
        resource: RowData<TData> | SimpleRowData<TData> | null,
        getRowsParams: IServerSideGetRowsParams,
        filterUri: string
    ): void {
        let totalItems: number = 0;
        let totalUnfiltered: number = 0;

        // fail if no valid response
        if (resource === null) {
            getRowsParams.failCallback();

            return;
        }

        // set rows in datatable
        if (Array.isArray(resource[hydraMember])) {
            totalItems = resource[hydraTotalItems];
            totalUnfiltered = resource[hydraUnfiltered];
            getRowsParams.successCallback(resource[hydraMember], totalItems);
        } else if (Array.isArray(resource['rows'])) {
            totalItems = resource['rows'].length;
            totalUnfiltered = totalItems;
            getRowsParams.successCallback(resource['rows'], totalItems);
        } else {
            getRowsParams.failCallback();

            return;
        }

        // check for first ajax call to perform some operations
        if (this.firstLoad) {
            // scroll to last row saved in the session on first load
            if (this.lastRow !== null && this.lastRow < totalItems) {
                this.api.ensureIndexVisible(this.lastRow, 'top');
            }

            this.firstLoad = false;
        }

        // make sure status bar component is found
        const statusBarComponent: IStatusPanelComp | undefined = this.api.getStatusPanel(
            'serverSideStatusBar'
        ) as IStatusPanelComp;
        if (statusBarComponent === undefined) {
            return;
        }

        // set total records on status bar
        this.totalRecords = totalUnfiltered;
        let displayRows: string = this.totalRecords.toString();
        if (filterUri !== '' && totalItems !== this.totalRecords) {
            displayRows = `${totalItems} of ${displayRows}`;
        } else {
            displayRows = `${displayRows}`;
        }

        const displayRowsSpan: HTMLSpanElement = statusBarComponent
            .getGui()
            .querySelector('.display-rows') as HTMLSpanElement;
        displayRowsSpan.innerHTML = displayRows;
    }

    /**
     * Returns the API uri of the current state of the Datatable
     *
     * @return string
     */
    getCurrentUri(): string {
        return this.currentUrl;
    }
}

export default ServerSideDataSource;
export { DataSourceType, DataSourceQueryFn, DataSourceQueryFnWithArgs };
