import { GridApi, ViewportChangedEvent } from '@ag-grid-community/core';
import { rowBuffer } from './config/defaultGridOptions';

// this information is stored as local session storage
interface SessionStorage {
    filterModel: Record<string, unknown>;
}

/**
 * Bind session saving to an gridEvent that fires when anything on the page changes.
 *
 * @param {String} id
 * @param {GridApi} api
 *
 * @return {void}
 */
export function bindSession(id: string, api: GridApi): void {
    api.addEventListener('paginationChanged', () => saveSession(id, api));
    api.addEventListener('viewportChanged', (gridEvent: ViewportChangedEvent) => saveSessionRow(id, gridEvent));
}

/**
 * Restore sorting, filtering, paging, page size set in local store
 *
 * @param {AgGrid} gridOptions
 */
export function restoreSession(id: string, api: GridApi): void {
    // get session storage
    const sessionStorage: SessionStorage | null = getSession(id);

    if (sessionStorage === null) {
        return;
    }

    // set filtering
    api.setFilterModel(sessionStorage.filterModel);
}

/**
 * Get the session row from local storage.  Stored separately as the row changing is a different
 * event (viewportChanged).
 *
 * @param {String} id
 *
 * @returns {number|null}
 */
export function getSessionRow(id: string): number | null {
    const sessionId: string = getSessionRowId(id);
    const item: string | null = window.sessionStorage.getItem(sessionId);
    if (item === null) {
        return null;
    }

    return parseInt(item);
}

/**
 * Get the session from local storage.
 *
 * @param {String} id
 *
 * @returns {SessionStorage|null}
 */
function getSession(id: string): SessionStorage | null {
    const sessionId: string = getSessionId(id);
    const item: string | null = window.sessionStorage.getItem(sessionId);
    if (item === null) {
        return null;
    }

    return JSON.parse(item);
}

/**
 * Save sorting, filtering, paging, page size to local storage.
 *
 * @param {GridApi} api
 *
 * @return {void}
 */
function saveSession(id: string, api: GridApi): void {
    // get values to be saved in the session
    const sessionStorage: SessionStorage = {
        filterModel: api.getFilterModel()
    };

    // save to session storage
    const sessionId: string = getSessionId(id);
    window.sessionStorage.setItem(sessionId, JSON.stringify(sessionStorage));
}

/**
 * Save the last row that was viewed in the table, used to display the row the user left off at.
 *
 * @param {GridApi} api
 *
 * @return {void}
 */
function saveSessionRow(id: string, gridEvent: ViewportChangedEvent): void {
    /*
     * Ag-grid 'firstRow' may not be visible because of the row buffer
     * The buffer may contain previous or next rows, try to calculate the best row position.
     */
    const row: number = gridEvent.firstRow > 0 ? gridEvent.firstRow + rowBuffer : gridEvent.firstRow;

    // save to session storage
    const sessionId: string = getSessionRowId(id);
    window.sessionStorage.setItem(sessionId, row.toString());
}

/**
 * Get a unique session id for the table per page by appending the page URI to the id.
 *
 * @param {String} id
 *
 * @return {String}
 */
function getSessionId(id: string): string {
    return id + '_' + window.location.pathname;
}

/**
 * Get a unique session id for the last row viewed in the table.
 *
 * @param {String} id
 *
 * @return {String}
 */
function getSessionRowId(id: string): string {
    return getSessionId(id) + '_row';
}
