import React, { ReactElement, useState, useImperativeHandle, forwardRef } from 'react';
import Flatpickr from 'react-flatpickr';
import { faCalendar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { merge } from 'lodash';
import { DateConfiguration, getSiteDateTimeConfig } from 'lib/sdk';

/**
 * Custom Date Picker component that utilizes date formats defined in Site Config
 */
const CustomDatePicker: React.ForwardRefExoticComponent<ReactElement> = forwardRef(function customDate(
    props: any,
    ref: any
) {
    const [date, setDate] = useState<string | null>(null);
    const dateConfig: DateConfiguration = getSiteDateTimeConfig();

    /**
     * Links the UI, the State and AgGrid
     *
     * @param {Array<string>} selectedDate
     * @return void
     */
    const onDateChanged: (selectedDates: Array<string>) => void = (selectedDates: Array<string>) => {
        setDate(selectedDates[0]);
        props.onDateChanged();
    };

    /**
     * Fix for AgGrid accessibility issue on filter drop down
     *
     * @return void
     */
    function onDateOpened(): void {
        document.querySelectorAll('.flatpickr-current-month *[tabIndex="-1"]').forEach((filterButton) => {
            filterButton.setAttribute('tabindex', '0');
        });
    }

    /**
     * Expose methods required by Ag-Grid using imperative handle
     */
    useImperativeHandle(ref, () => ({
        getDate() {
            //ag-grid will call us here when in need to check what the current date value is held by this component.
            return date;
        },
        setDate(date) {
            //ag-grid will call us here when it needs this component to update the date that it holds.
            setDate(date);
        }
    }));

    return (
        <div className='agGridDatePicker'>
            <Flatpickr
                options={merge({
                    wrap: true,
                    dateFormat: dateConfig.flatPickrDateFormat,
                    locale: { firstDayOfWeek: dateConfig.weekStartDate }
                })}
                value={date}
                onChange={onDateChanged}
                onOpen={onDateOpened}
            >
                <input
                    className='ag-input-field-input ag-text-field-input'
                    type='text'
                    placeholder='Select Date...'
                    data-input
                />
                <a className='calendar' title='toggle' aria-label='Choose Date' data-toggle>
                    <FontAwesomeIcon icon={faCalendar} />
                </a>
                <a className='cancel' aria-label='Clear Date' title='clear' data-clear>
                    <FontAwesomeIcon icon={faTimes} />
                </a>
            </Flatpickr>
        </div>
    );
});

export { CustomDatePicker };
