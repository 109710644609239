import React, { ReactElement } from 'react';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndoAlt, faFileAlt, faCompressArrowsAlt, faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import { CustomOptions } from '../config/getConfigProps';

export default function StatusBarButtons(
    customOptions: CustomOptions,
    handlePrint: () => void,
    handleResetColumns: () => void,
    handleRestoreColumns: () => void,
    handleExportAll: () => void,
    handleExportView: () => void
): ReactElement {
    return (
        <div className='status-bar-buttons my-1'>
            {customOptions['printView'] && (
                <button onClick={handlePrint} className='btn btn-secondary rounded-0 mr-2'>
                    <p className='pr-2 p__inline'>
                        <FontAwesomeIcon icon={faPrint} />
                    </p>
                    {i18next.t('general:datatablePrint')}
                </button>
            )}

            {(customOptions['exportView'] === undefined || customOptions['exportView']) && (
                <button onClick={handleExportView} className='btn btn-secondary rounded-0 mr-2'>
                    <p className='pr-2 p__inline'>
                        <FontAwesomeIcon icon={faFileAlt} />
                    </p>
                    {i18next.t('general:datatableExportView')}
                </button>
            )}

            {(customOptions['exportAll'] === undefined || customOptions['exportAll']) && (
                <button onClick={handleExportAll} className='btn btn-secondary rounded-0 mr-4'>
                    <p className='pr-2 p__inline'>
                        <FontAwesomeIcon icon={faDownload} />
                    </p>
                    {i18next.t('general:datatableExportAll')}
                </button>
            )}

            {(customOptions['restoreBtn'] === undefined || customOptions['restoreBtn']) && (
                <button onClick={handleRestoreColumns} className='btn btn-outline-dark rounded-0 mr-2'>
                    <p className='pr-2 p__inline'>
                        <FontAwesomeIcon icon={faUndoAlt} />
                    </p>
                    {i18next.t('general:restoreDefaults')}
                </button>
            )}

            {(customOptions['resetBtn'] === undefined || customOptions['resetBtn']) && (
                <button onClick={handleResetColumns} className='btn btn-outline-dark rounded-0 mr-2'>
                    <p className='pr-2 p__inline'>
                        <FontAwesomeIcon icon={faCompressArrowsAlt} />
                    </p>
                    {i18next.t('general:resetColumns')}
                </button>
            )}
        </div>
    );
}
