import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { policyLibraryApp, getDomain } from 'lib/sdk';
import '../AgGrid';
import { ConfigProps, Datatable, getConfigProps, UrlReplacementString } from 'lib/aggrid';
import { Button, Modal } from 'lib/components';
import { faFilePdf, faSearch, faBook } from '@fortawesome/pro-light-svg-icons';
import { domainEnv, mcnDataDomain } from 'lib/types';
import { useTranslation } from 'react-i18next';
import { graphql, StaticQueryDocument } from 'gatsby';
import Layout from '../components/layout';

type PlibProps = {
    description: string;
    s3FileLocation: string;
};

type PmanProps = {
    name: string;
};

type Details = {
    plibDetails: PlibProps;
    pmanDetails: PmanProps;
};

type DataProps = {
    data: { policylibrary: Details };
    pageContext: { slug: string; id: number };
};

const query: StaticQueryDocument = graphql`
    query ManualPage($id: String!) {
        policylibrary {
            plibDetails: getPolicyLibrary(id: $id, sk: "details#live") {
                s3FileLocation
                description
            }
            pmanDetails: getPolicyManagerFolder(id: $id) {
                name
            }
        }
        locales: allLocale(filter: { ns: { in: ["general", "policy-library"] } }) {
            edges {
                node {
                    language
                    data
                    ns
                }
            }
        }
    }
`;

/**
 * This gets the folder and documents from a specific manual/folder the id can be a folder at any level
 *
 * @return {React.ReactElement}
 */
function ManualDetails({ data, pageContext }: DataProps): React.ReactElement {
    const { t } = useTranslation();

    /*
     * Used for the modal on the sample file button
     */
    const [show, setShow] = useState<boolean>(false);

    /**
     * Close handle for page.
     *
     * @var {any}
     */
    // eslint-disable-next-line @typescript-eslint/typedef
    const handleClose = () => setShow(false);

    /**
     * Show handle for page.
     *
     * @var {any}
     */
    // eslint-disable-next-line @typescript-eslint/typedef
    const handleShow = () => setShow(true);

    /**
     * Library slug.
     *
     * @var {string}
     */
    // const [librarySlug] = useState<string>(slug !== 'searching' ? slug : 'international-hospital-library');

    /**
     * The url of sample files.
     *
     * @var {string}
     */
    const [sampleUrl, setSampleUrl] = useState<string>();

    /**
     * Navigates to previos page.
     *
     * @return {void}
     */
    // function prevClicked(): void {
    //     navigation(-1);
    // }

    /**
     * Updates UI
     *
     * @return {void}
     */
    useEffect(() => {
        if (process.env.SAMPLEFILE_URL !== undefined) {
            setSampleUrl(process.env.SAMPLEFILE_URL);
        } else {
            // examples.development.policylibrary.mcn-data.net
            const domain: string = getDomain();
            const env: string = domainEnv[domain];
            setSampleUrl(`https://examples.${env}.policylibrary.${mcnDataDomain}`);
        }
    });

    /**
     * Setups the config values for ag-grid, that list the folders and documents of the manuals
     *
     * @var {ConfigProps}
     */
    const config: ConfigProps = getConfigProps('policyLibrary');

    /**
     * Renders react element
     *
     * @return {ReactElement}
     */
    return (
        <Layout>
            <Helmet>
                <title>
                    {t('policy_library')} - {data.policylibrary.pmanDetails.name}
                </title>
                <meta
                    property='og:title'
                    content={`${t('general:policy_library')} - ${data.policylibrary.pmanDetails.name}`}
                />
                <meta
                    property='og:url'
                    content={`https://policylibrary.mcnhealthcare.com/manual/${pageContext.slug}`}
                />
            </Helmet>

            <div>
                <h4 className='d-flex justify-content-center'>{data.policylibrary.pmanDetails.name}</h4>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: data.policylibrary.plibDetails.description }} />
                <hr />

                <div className='d-flex justify-content-between w-100 mb-3'>
                    {/* 
                    <Button
                        icon={slug === 'searching' ? faSearch : faBook}
                        size='lg'
                        variant='outline-secondary'
                        buttonText={t(slug === 'searching' ? slug : 'library')}
                        onClick={prevClicked}
                    />
*/}
                    {data.policylibrary.plibDetails.s3FileLocation && (
                        <Button
                            icon={faFilePdf}
                            size='lg'
                            variant='outline-primary'
                            buttonText={t('sample_file.view')}
                            onClick={handleShow}
                        />
                    )}
                </div>

                <h5>{t('folder_documents')}</h5>

                <Datatable
                    type='inline'
                    app={policyLibraryApp}
                    url={`/manuals/${UrlReplacementString}`}
                    id='structure'
                    defaultId={pageContext.id}
                    enableStatusBar={false}
                    enableDatatableState={false}
                    {...config}
                />

                {data.policylibrary.plibDetails.s3FileLocation && (
                    <Modal id='modal' title='Sample File' show={show} onHide={handleClose} animation={false}>
                        <iframe
                            width='99%'
                            height='99%'
                            src={`${sampleUrl}/${data.policylibrary.plibDetails.s3FileLocation}`}
                        ></iframe>
                    </Modal>
                )}
            </div>
        </Layout>
    );
}

export { ManualDetails as default, query };
