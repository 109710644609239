import { GridApi } from '@ag-grid-community/core';

export interface ClientSideDatatableState<T> {
    key: string;
    data?: T[];
}

/**
 * Set client side rows after grid is ready and ajax get is finished.
 *
 * @return Promise<void>
 */
export async function getRows<T>(data: T[], api: GridApi, status: string): Promise<void> {
    const rows: T[] = data;

    // return if no data
    if (!Array.isArray(rows) || rows.length === 0) {
        api.setRowData([]);
        return;
    }

    // Load Overlay based on status
    if (status === 'pending') {
        api.showLoadingOverlay();
    } else {
        // add data to grid
        api.applyTransaction({ add: rows });
    }
}
