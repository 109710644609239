import { ConfigProps, setConfigContext } from 'lib/aggrid';

/**
 * Loads all config files in './AgGrid/configs' directory for Datatable component.
 * All configs should have 'export default function' that returns an object literal
 * of the configuration for ag-grid.
 */
const requireContext: __WebpackModuleApi.RequireContext = require.context('./configs', false, /\.ts$/);
const configs: ConfigProps[] = [];
let key: string;
requireContext.keys().forEach((configFile) => {
    key = configFile.slice(2, -3);
    configs[key] = requireContext(configFile).default;
});
setConfigContext(configs);
