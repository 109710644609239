import { IStatusPanelComp, IStatusPanelParams } from '@ag-grid-community/core';

/**
 * Implement a status bar at the bottom for server-side model that displays the filtered results and total results.
 */
export default class ServerSideStatusBar implements IStatusPanelComp {
    protected params: IStatusPanelParams | null = null;
    protected eGui: HTMLElement | null = null;

    /**
     * Initiate the status bar.
     *
     * @param {IStatusPanelParams} params
     *
     * @return {void}
     */
    init(params: IStatusPanelParams): void {
        this.params = params;

        this.eGui = document.createElement('div');
        this.eGui.className = 'ag-name-value ag-status-panel ag-status-panel-total-and-filtered-row-count';

        const label: HTMLSpanElement = document.createElement('p');
        label.innerText = 'Rows:\xa0';
        this.eGui.appendChild(label);

        const displayRowsSpan: HTMLSpanElement = document.createElement('p');
        displayRowsSpan.className = 'ag-name-value-value display-rows';
        this.eGui.appendChild(displayRowsSpan);
    }

    /**
     * Get the status bar dom element.
     *
     * @return {HTMLElement}
     */
    getGui(): HTMLElement {
        return this.eGui as HTMLElement;
    }
}
