import { GridOptions, ServerSideStoreType } from '@ag-grid-community/core';
import ServerSideStatusBar from '../features/ServerSideStatusBar';
import { CustomDatePicker } from '../features/CustomDatePicker';
import { CustomTooltip } from '../features/ToolTip';

const rowBuffer: number = 20;

const defaultGridOptions: GridOptions = {
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial',
    rowBuffer: rowBuffer,
    rowHeight: 50,
    suppressDragLeaveHidesColumns: true,
    suppressContextMenu: true,
    components: {
        serverSideStatusBar: ServerSideStatusBar
    },
    defaultColDef: {
        lockPinned: true,
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
            suppressAndOrCondition: true
        },
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc'],
        minWidth: 50,
        tooltipComponent: 'customTooltip'
    },
    suppressPaginationPanel: true,
    tooltipShowDelay: 2000,
    frameworkComponents: { agDateInput: CustomDatePicker, customTooltip: CustomTooltip }
};

export { defaultGridOptions, rowBuffer };
