import React from 'react';
import { GridOptions, ColDef } from '@ag-grid-community/core';
import { putResource, datatableApp, getBaseUrl } from 'lib/sdk';
import { NotificationEvent } from 'lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

type ExportType = 'view' | 'all';

interface ExportRequestProps {
    type: ExportType;
    id: string;
    uuid: string;
    app: string;
    uri: string;
    gridOptions: GridOptions;
    authUserId: string;
    addNotification?: (event: NotificationEvent) => Promise<void>;
    externalFilterField?: string;
}

/**
 * Extracts the grid name (ex. Site Messages for site-message-datatable)
 *
 * @param id
 */
function getGridName(id: string): string {
    // Get the grid name based on it's id, adjust to make it plural
    const dtIndex: number = id.indexOf('-datatable');
    const nameStr: string = id.substr(0, dtIndex);
    const nameArr: string[] = nameStr.split('-');
    let gridName: string = '';
    for (const piece of nameArr) {
        gridName = `${gridName} ${piece[0].toUpperCase() + piece.substring(1)}`;
    }
    gridName = gridName.trim();
    if (gridName.slice(-1) !== 's') {
        gridName = gridName + 's';
    }
    if (gridName.slice(-2) === 'ys') {
        gridName = gridName.substring(0, gridName.length - 2) + 'ies';
    }

    return gridName;
}

/**
 * Sends a request to the Export API to generate a CSV file.
 * This is all handled by the sam-datatable-api, will push a message with a download link through the websocket
 * connection when the file is ready.
 *
 * @return {void}
 * @param {ExportRequestProps} props
 */
function sendExportRequest(props: ExportRequestProps): void {
    const baseUrl: string | undefined = getBaseUrl(props.app, true);
    const endpoint: string = `${baseUrl}${props.uri}`.replace(/(^\w+:|^)\/\//, '');

    if (props.gridOptions.columnDefs === undefined || props.gridOptions.columnDefs === null) {
        return;
    }

    const colDefs: ColDef[] = props.gridOptions.columnDefs.filter(function (colDef) {
        return !(!Object.prototype.hasOwnProperty.call(colDef, 'field') || colDef['field'] === 'Link');
    });

    const requestBody: Record<string, string> = {
        type: props.type,
        app: props.app,
        endpoint: endpoint,
        gridName: getGridName(props.id),
        gridContext: btoa(unescape(encodeURIComponent(JSON.stringify(props.gridOptions.context)))),
        columnDefs: btoa(
            unescape(
                encodeURIComponent(
                    JSON.stringify(colDefs, (key, value) => {
                        if (key === 'valueGetter' || key === 'valueFormatter') {
                            return btoa(value.toString());
                        }
                        return value;
                    })
                )
            )
        ),
        uuid: props.uuid
    };

    if (process.env.DEVELOPER_NAME) {
        requestBody['developerName'] = process.env.DEVELOPER_NAME;
    }

    if (props.externalFilterField) {
        requestBody['externalFilterField'] = props.externalFilterField;
    }

    putResource('/export', requestBody, datatableApp);

    if (props.addNotification !== undefined) {
        props.addNotification({
            type: 'alert',
            headerText: 'Export Generating',
            body: <div>Your file is being generated</div>,
            icon: <FontAwesomeIcon icon={faSpinnerThird} className={'fa-spin'} />,
            uuid: props.uuid
        });
    }
}

export { sendExportRequest, ExportType, ExportRequestProps };
