import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from '../translations';

import { ModuleRegistry, AllCommunityModules } from '@ag-grid-community/all-modules';
import { LicenseManager, AllEnterpriseModules } from '@ag-grid-enterprise/all-modules';

LicenseManager.setLicenseKey(
    'CompanyName=Medical Consultants Network Inc,LicensedApplication=MCN Healthcare,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=1,AssetReference=AG-018923,ExpiryDate=29_October_2022_[v2]_MTY2Njk5ODAwMDAwMA==2e163978c8d5e1ff4c828eb571b9b86d'
);

// register ag-grid modules globally
ModuleRegistry.registerModules(AllCommunityModules);
ModuleRegistry.registerModules(AllEnterpriseModules);

/*
 * This is the i18next config, this is needed to configure i18n from Mercury
 * Main thing to note is the defaultNS, this will determine which keys we have access to from Mercury Locales.
 */
i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: 'en',
    load: 'languageOnly',
    resources,
    ns: ['general'],
    defaultNS: 'general'
});

// For tree views the url to retrieve the data has this in place of the id, so that can be dynamically inserted when opening a node
const UrlReplacementString: string = '{{UrlReplacementString}}';

export * from './src';
export { UrlReplacementString };
