import { ConfigFile, CustomOptions } from 'lib/aggrid';
import { GridOptions, RowNode } from '@ag-grid-community/core';

export default function (): ConfigFile {
    const gridOptions: GridOptions = {
        headerHeight: 0,
        floatingFiltersHeight: 0,
        allowContextMenuWithControlKey: true,
        suppressContextMenu: true,
        suppressMenuHide: true,
        treeData: true,
        defaultColDef: {
            flex: 1,
            filter: false,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
            columnsMenuParams: {
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true
            },
            suppressMovable: true,
            suppressMenu: true,
            sortable: false,
            resizable: false,
            editable: false
        },
        autoGroupColumnDef: {
            field: 'name',
            cellRendererParams: {
                innerRenderer: (params: RowNode) => {
                    return params.data.name;
                }
            }
        },
        animateRows: true,
        serverSideStoreType: 'partial',
        isServerSideGroup: (dataItem) => {
            return dataItem.type === 'Folder';
        },
        getServerSideGroupKey: (dataItem) => {
            return dataItem.id;
        }
    };

    const customOptions: CustomOptions = {
        exportAll: false,
        exportView: false,
        resetBtn: false,
        restoreBtn: false
    };

    return { gridOptions, customOptions };
}
