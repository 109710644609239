import { GridOptions, ColDef, ColumnApi, GridApi, ColGroupDef } from '@ag-grid-community/core';
import { merge } from 'lodash';

/**
 * Config options that can be passed in to customize the ag-grid options.
 *
 * @template TArgs - Arguments given to the config function.
 */
interface ConfigOptions<TArgs = Record<string, unknown>> {
    isMultiFacility?: boolean;
    headerNames?: Record<string, string>;
    customOptions?: CustomOptions;
    args?: TArgs;
}

// custom colDef in gridOptions that can be used to customize agGridOptions for single or multifacility
interface CustomColDef extends ColDef {
    isFacility?: boolean;
}

/** Config object returned by the config file, the customOptions is optional. */
interface ConfigFile {
    gridOptions: GridOptions;
    customOptions?: CustomOptions;
    callbacks?: Callback[];
}

/** Object of props that will be passed in for the ag-grid architecture, contains ag-grid props and custom props. */
type ConfigProps = Required<ConfigFile>;

/**
 * Custom options that may be used by custom ag-grid features/components like 'ServerSideDataSource' and 'rowSelect'.
 * This is the simplest way to pass anything to custom features, add to this as needed.
 */
interface CustomOptions {
    filterModel?: FilterModel;
    sortModel?: SortModel;
    selectedRows?: string[];
    url?: string;
    app?: string;
    printView?: boolean;
    exportAll?: boolean;
    exportView?: boolean;
    resetBtn?: boolean;
    restoreBtn?: boolean;
}

interface SortModel {
    [index: string]: {
        fields: string[];
    };
}

interface FilterModel {
    [index: string]: {
        fields?: string[];
        filter?: string;
        dateFrom?: string;
        type?: string;
        filterTo?: string | number | boolean;
        filterType?: string;
        values?: string[];
    };
}

/**
 * Callback events that will be run after the grid is instantiated.  This is used to change settings,
 * bind listeners, etc. after the ag-grid component is created.
 */
interface Callback {
    event: CallbackEventTypes;
    callable: (callableParams: CallableParams) => void;
}

// list of event types that the Datatable class will dispatch, add to the list if creating more
type CallbackEventTypes = 'onGridReadyEnd' | 'onGridReadyStart';

// these parameters are passed as an object to the 'callable' so destructuring can be used in the callback
interface CallableParams {
    id: string;
    api: GridApi;
    columnApi: ColumnApi;
    customOptions: CustomOptions;
}

let configs: ConfigProps[] = [];

/**
 * Set config context
 *
 * @param newConfigs
 *
 * @return {void}
 */
function setConfigContext(newConfigs: ConfigProps[]): void {
    configs = newConfigs;
}

/**
 * Return a config from the ./configs folder that is customized for multi-facility, language, etc.
 *
 * @param {String}        configName
 * @param {ConfigOptions} configOptions
 *
 * @return {ConfigProps}
 */
function getConfigProps(configName: string, configOptions: ConfigOptions = {}): ConfigProps {
    // make sure the config file was found
    if (!(configName in configs)) {
        throw `AgGrid configuration file '${configName}' not found`;
    }

    // configs are run as a function so not all configs are evaluated on page load
    const config: (ConfigOptions: ConfigOptions) => ConfigFile = configs[configName];
    const returnConfig: ConfigFile = config(configOptions);
    const { gridOptions }: ConfigFile = returnConfig;
    let { customOptions, callbacks }: ConfigFile = returnConfig;

    // override the header names with custom headers
    if (configOptions.headerNames !== undefined) {
        configureHeaderNames(gridOptions, configOptions.headerNames);
    }

    // check for any custom options to be passed, the configOptions will overwrite any default custom options
    if (configOptions.customOptions) {
        if (customOptions === undefined) {
            customOptions = configOptions.customOptions;
        } else {
            customOptions = merge({}, customOptions, configOptions.customOptions);
        }
    }

    // add default custom options if none was given
    if (customOptions === undefined) {
        customOptions = {};
    }

    // add default callbacks if none was given
    if (callbacks === undefined) {
        callbacks = [];
    }

    return { gridOptions, customOptions, callbacks };
}

/**
 * Updates the grid to hide/show multifacility-classed columns in the grid
 *
 * @param {GridApi} api
 * @param {boolean} isMultifacility
 * @param defaultColDefs
 *
 * @return {void}
 */
function updateFacilityColumns(api: GridApi, defaultColDefs: ColDef[], isMultifacility: boolean): void {
    // Get the current column defs for comparison later
    const currentColumnDefs: (ColDef | ColGroupDef)[] | undefined = api.getColumnDefs();

    // Create a list of new colunn defs based on the default defs defined for this grid
    const newColumnDefs: ColDef[] = filterFacilityColumns(isMultifacility, defaultColDefs);

    // Only need to update the columnDefs if the newColumnDefs is different than the current ones
    if (currentColumnDefs !== undefined && currentColumnDefs.length !== newColumnDefs.length) {
        // The only way AG-Grid will repaint the grid after column changes is to reset column defs, then set the new value
        // https://github.com/ag-grid/ag-grid/issues/2771
        api.setColumnDefs([]);
        api.setColumnDefs(newColumnDefs);
    }
}

/**
 * Filters multifacility-only columns in our out of the supplied column definitions
 *
 * @param isMultifacility
 * @param defaultColDefs
 *
 * @return {ColDef[]}
 */
function filterFacilityColumns(isMultifacility: boolean, defaultColDefs: ColDef[]): ColDef[] {
    // loop through column definitions
    const newColumnDefs: ColDef[] = [];
    defaultColDefs.forEach(function (column: ColDef) {
        // add non-facility columns

        // add the facility column if the site is multi-facility
        if (column.headerClass !== 'multifacility' || (isMultifacility && column.headerClass === 'multifacility')) {
            newColumnDefs.push(column);
        }
    });

    return newColumnDefs;
}

/**
 * Returns true if the ColDef contains a facility-specific column, used to determine if ColDefs need to be updated
 * when components update
 *
 * @param {ColDef[]} defaultColDefs
 *
 * @return {boolean}
 */
function hasFacilityColDefs(defaultColDefs: ColDef[]): boolean {
    for (const column of defaultColDefs) {
        // add the facility column if the site is multi-facility
        if (column.headerClass === 'multifacility') {
            return true;
        }
    }

    return false;
}

/**
 * Override headers with custom names
 *
 * @param {GridOptions} configGridOptions
 * @param {Object} headerNames
 *
 * @return {void}
 */
function configureHeaderNames(configGridOptions: GridOptions, headerNames: Record<string, string>): void {
    if (configGridOptions.columnDefs === undefined || configGridOptions.columnDefs === null) {
        return;
    }

    // loop through each column and replace header name if needed
    configGridOptions.columnDefs.forEach(function (value: ColDef, index: number) {
        if (
            configGridOptions.columnDefs !== undefined &&
            configGridOptions.columnDefs !== null &&
            value.field !== undefined &&
            headerNames[value.field] !== undefined
        ) {
            configGridOptions.columnDefs[index].headerName = headerNames[value.field];
        }
    });
}

export {
    SortModel,
    FilterModel,
    ConfigFile,
    ConfigOptions,
    ConfigProps,
    Callback,
    CallbackEventTypes,
    CustomOptions,
    setConfigContext,
    getConfigProps,
    hasFacilityColDefs,
    filterFacilityColumns,
    updateFacilityColumns
};
