import { ColumnApi, GridApi, ColumnState } from '@ag-grid-community/core';
import { putResource, getResourceItem, datatableApp } from 'lib/sdk';

interface DatatableState {
    externalFilter?: string;
    state: ColumnState[];
}

interface DatatableApiResult extends Omit<DatatableState, 'state'> {
    state: string;
}

/**
 * Bind saving the state when a user changes the column state.
 *
 * @param {string} id
 * @param {string|undefined} product
 * @param {string|undefined} user
 * @param {GridApi} api
 * @param {ColumnApi} columnApi
 *
 * @return void
 */
function bindColumnState(id: string, product: string | undefined, api: GridApi, columnApi: ColumnApi): void {
    api.addEventListener('columnVisible', () => saveDatatableState(id, product, columnApi));
    api.addEventListener('columnPinned', () => saveDatatableState(id, product, columnApi));
    api.addEventListener('dragStopped', () => saveDatatableState(id, product, columnApi));
    api.addEventListener('sortChanged', () => saveDatatableState(id, product, columnApi));
}

/**
 * Gets Column State
 *
 * @param {string} id
 * @param {string|undefined} product
 *
 * @return {Promise}
 */
async function getDatatableState(id: string, product: string | undefined): Promise<DatatableState> {
    const datatableState: DatatableState = { state: [], externalFilter: '' };

    const datatableSettings: DatatableApiResult | null = await getResourceItem(
        `/user-settings?product=${product}&name=${id}`,
        datatableApp
    );

    if (datatableSettings && datatableSettings?.state !== '') {
        datatableState.state = JSON.parse(atob(datatableSettings.state));
        datatableState.externalFilter = datatableSettings.externalFilter;
    }

    return datatableState;
}

/**
 * Save the state via ajax to the database.
 *
 * @param {string} id
 * @param {string|undefined} product
 * @param {string|undefined} user
 * @param {ColumnApi} columnApi
 * @param {string|undefined} externalFilter
 *
 * @return {void}
 */
function saveDatatableState(
    id: string,
    product: string | undefined,
    columnApi: ColumnApi,
    externalFilter: string | undefined = ''
): void {
    putResource(
        '/user-settings',
        {
            name: id,
            product: product,
            state: btoa(JSON.stringify(columnApi.getColumnState())),
            externalFilter: externalFilter
        },
        datatableApp
    );
}

export { bindColumnState, saveDatatableState, getDatatableState, DatatableState };
