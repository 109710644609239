import React, { Component, ReactElement } from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AgGridReact as AgGridReactBase } from '@ag-grid-community/react/lib/agGridReact.d';
import {
    GridOptions,
    ColumnApi,
    GridApi,
    GridReadyEvent,
    RowNode,
    GetMainMenuItemsParams,
    MenuItemDef,
    ColDef
} from '@ag-grid-community/core';
import { cloneDeep, merge, forEach } from 'lodash';
import { Loader, SaveButton, TableFilter } from 'lib/components';
import { noApp } from 'lib/sdk';
import {
    DatatableProps,
    DefaultDatatableProps,
    DatatableClassState,
    HandleSaveParams,
    ChangedItems,
    FilterTabsMap
} from './DatatableProps';
import { bindColumnState, getDatatableState, saveDatatableState, DatatableState } from './DatatableState';
import { restoreSession, bindSession, getSessionRow } from './DatatableSession';
import { getRows } from './ClientSideDataSource';
import ServerSideDataSource, { DataSourceType } from './ServerSideDataSource';
import {
    CustomOptions,
    CallbackEventTypes,
    updateFacilityColumns,
    hasFacilityColDefs,
    filterFacilityColumns
} from './config';
import { defaultGridOptions } from './config/defaultGridOptions';
import StatusBarButtons from './features/StatusBarButtons';
import { sendExportRequest, ExportType, ExportRequestProps } from './DatatableExport';
import { RowSelectedEvent } from '@ag-grid-enterprise/all-modules';

/**
 * @template TData - Type of the data.
 *
 * Datatable component that wraps ag-grid
 */
class Datatable<TData> extends Component<DatatableProps<TData>, DatatableClassState> {
    protected agGrid: React.RefObject<AgGridReactBase> = React.createRef<AgGridReactBase>();
    protected gridOptions: GridOptions;
    protected customOptions: CustomOptions;
    protected api: GridApi | null = null;
    protected columnApi: ColumnApi | null = null;
    protected disableSave: boolean | undefined;
    protected serverSideDataSource: ServerSideDataSource<TData> | undefined;
    protected selectedRows: Record<string, boolean> = {};
    protected defaultColDefs?: ColDef[];
    protected hasFacilityColDefs: boolean = false;
    protected defaultFilter?: FilterTabsMap;
    protected filterTabs?: FilterTabsMap[];

    // Create a filterType property and set the initial value to our props.defaultFilter value
    protected filterType?: FilterTabsMap;

    // Default props if not set
    static readonly defaultProps: DefaultDatatableProps = {
        customOptions: {},
        callbacks: [],
        fullHeight: false,
        disableSave: false
    };

    /**
     * default grid options, may be overwritten with passed in options
     */
    protected readonly defaultConfig: GridOptions = {
        ...defaultGridOptions,
        defaultColDef: {
            ...defaultGridOptions.defaultColDef,
            comparator: this.insensitiveComparator.bind(this)
        },
        onGridReady: this.onGridReady.bind(this)
    };

    /**
     * @param {DatatableProps} props
     */
    constructor(props: DatatableProps<TData>) {
        super(props);

        this.state = { isStateReady: false, selected: {} };

        // merge custom config and default config, custom config will override defaults
        const gridOptions: GridOptions = merge({}, this.defaultConfig, props.gridOptions);

        // undefined if not passed, so going to default to true to match the current setup
        const enableStatusBar: boolean = props.enableStatusBar !== undefined ? props.enableStatusBar : true;

        if (enableStatusBar) {
            // check for server side model, set server side settings
            let statusBarComponent: string = 'agTotalAndFilteredRowCountComponent';
            if (!this.props.data) {
                gridOptions.cacheBlockSize = 20;
                gridOptions.maxBlocksInCache = 4;
                statusBarComponent = 'serverSideStatusBar';
            }

            // set status bar
            gridOptions.statusBar = {
                statusPanels: [
                    { statusPanel: statusBarComponent, align: 'left' },
                    {
                        statusPanelFramework: (): ReactElement => {
                            return StatusBarButtons(
                                props.customOptions,
                                this.handlePrint,
                                this.handleResetColumns,
                                this.handleRestoreColumns,
                                this.handleExportAll,
                                this.handleExportView
                            );
                        },
                        align: 'right'
                    }
                ]
            };
        }

        gridOptions.detailCellRendererParams = {
            startOfWeek: props.startOfWeek
        };

        // save datetime format & timezone in gridOptions as context
        gridOptions.context = {
            dateFormat: props.dateFormat,
            timeFormat: props.timeFormat,
            timezone: props.timezone
        };

        this.defaultColDefs = gridOptions.columnDefs ?? undefined;

        // set the config for multi or single facility if specified
        if (this.defaultColDefs && props.isMultiFacility !== undefined && hasFacilityColDefs(this.defaultColDefs)) {
            this.hasFacilityColDefs = true;
            gridOptions.columnDefs = filterFacilityColumns(props.isMultiFacility, this.defaultColDefs);
        }

        // Handle sorting and filtering ColumnDefs if drag and drop table
        if (this.props.data && this.props.rowDragManaged) {
            this.defaultColDefs?.forEach(function (colDef) {
                colDef.sortable = false;
                colDef.resizable = false;
                colDef.floatingFilter = false;
            });
        }

        // Convert default filter in string format to FilterTabsMap format
        if (typeof this.props.defaultFilter === 'string') {
            this.defaultFilter = {
                name: this.props.defaultFilter,
                value: this.props.defaultFilter
            };
        } else {
            this.defaultFilter = this.props.defaultFilter;
        }

        // Convert filterTabs string format to FilterTabsMap[] format
        if (this.props.filterTabs) {
            const filterTabs: FilterTabsMap[] = [];
            for (const tab of this.props.filterTabs) {
                if (typeof tab === 'string') {
                    const tabDetail: FilterTabsMap = {
                        name: tab
                    };
                    if (tab !== 'all') {
                        tabDetail.value = tab;
                    }
                    filterTabs.push(tabDetail);
                } else {
                    filterTabs.push(tab);
                }
            }
            this.filterTabs = filterTabs;
        }

        // hold instance variables
        this.gridOptions = gridOptions;

        // deep copy the customOptions prop to not mutate it directly
        this.customOptions = cloneDeep(props.customOptions);

        // set binding scope of methods
        this.onSave = this.onSave.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleResetColumns = this.handleResetColumns.bind(this);
        this.handleRestoreColumns = this.handleRestoreColumns.bind(this);
        this.externalFilterChanged = this.externalFilterChanged.bind(this);
        this.isExternalFilterPresent = this.isExternalFilterPresent.bind(this);
        this.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
        this.notifyParentGridIsReady = this.notifyParentGridIsReady.bind(this);
        this.handleExportView = this.handleExportView.bind(this);
        this.handleExportAll = this.handleExportAll.bind(this);
        this.handleRowDragEnd = this.handleRowDragEnd.bind(this);
    }

    /**
     * Lifecycle event when the component is updated. Checking to see if isMultiFacility prop changes so columns can
     * be added/removed from the grid if necessary.
     *
     * @param prevProps
     */
    componentDidUpdate(prevProps: Readonly<DatatableProps<TData>>): void {
        if (
            this.hasFacilityColDefs &&
            this.api &&
            this.defaultColDefs &&
            this.props.isMultiFacility !== undefined &&
            this.props.isMultiFacility !== prevProps.isMultiFacility
        ) {
            updateFacilityColumns(this.api, this.defaultColDefs, this.props.isMultiFacility);
        }
    }

    /**
     * Binds events for server-side row selection
     *
     * @return {void}
     */
    bindServerSideSelection(): void {
        this.api?.addEventListener('rowSelected', (event): void => {
            this.selectedRows[event.node.id] = event.node.selected;
        });

        this.api?.addEventListener('modelUpdated', (): void => {
            this.api?.getRenderedNodes().forEach((node) => {
                if (node.id && this.selectedRows[node.id] !== undefined) {
                    node.setSelected(this.selectedRows[node.id]);
                }
            });
        });
    }

    /**
     * Returns the data source.
     *
     * @return DataSourceType<TData>
     */
    getDataSource(): DataSourceType<TData> {
        return (this.props.url ?? this.props.query ?? '#') as DataSourceType<TData>;
    }

    /**
     * Ran when the 'gridReady' gridEvent fires, used to bind events.
     *
     * @param {GridReadyEvent} gridEvent
     *
     * @return void
     */
    async onGridReady(gridEvent: GridReadyEvent): Promise<void> {
        this.api = gridEvent.api;
        this.columnApi = gridEvent.columnApi;

        // dispatch any callbacks before gridReady work is done
        this.dispatchEvent('onGridReadyStart', this.api, this.columnApi);

        // get the last row scrolled to before setting anything because 'viewportChanged' event will fire
        const lastRow: number | null = getSessionRow(this.props.id);

        // bind grid events
        bindSession(this.props.id, this.api);

        // set grid filter settings from session
        restoreSession(this.props.id, this.api);

        if (this.isDatatableSessionEnabled()) {
            bindColumnState(this.props.id, this.props.app, this.api, this.columnApi);
        }

        const dataSource: DataSourceType<TData> = this.getDataSource();
        const app: string = this.props.app ?? noApp;
        const status: string = this.props.status ?? '';
        const defaultId: string | number = this.props.defaultId ?? '';

        // set datasource
        if (this.props.data) {
            getRows(this.props.data, this.api, status);
        } else {
            this.serverSideDataSource = new ServerSideDataSource<TData>(
                dataSource,
                app,
                this.api,
                this.customOptions,
                lastRow,
                this.defaultFilter,
                this.props.filterBy,
                this.gridOptions.columnDefs ?? [],
                defaultId
            );
            this.api.setServerSideDatasource(this.serverSideDataSource);
        }

        this.setState({ isStateReady: true });
        if (this.props.gridReady !== undefined) {
            this.notifyParentGridIsReady();
        }

        if (this.isDatatableSessionEnabled()) {
            // restore datatable state
            const datatableState: DatatableState = await getDatatableState(this.props.id, this.props.app);

            if (datatableState.state === null) {
                if (this.api['columnController'].columnDefs.length <= 9) {
                    this.api.sizeColumnsToFit();
                } else {
                    this.columnApi.autoSizeAllColumns();
                }

                saveDatatableState(this.props.id, this.props.app, this.columnApi);
            } else if (
                datatableState.state.length > 0 &&
                this.props.gridOptions.columnDefs !== undefined &&
                this.props.gridOptions.columnDefs !== null &&
                datatableState.state.length === this.props.gridOptions.columnDefs.length
            ) {
                this.columnApi.applyColumnState({ state: datatableState.state });
            } else {
                this.api.sizeColumnsToFit();
            }
        } else {
            this.api.sizeColumnsToFit();
        }

        // dispatch any callbacks after gridReady work is done
        this.dispatchEvent('onGridReadyEnd', this.api, this.columnApi);
        this.bindServerSideSelection();
    }

    /**
     * Custom Comparator to make the sort functionality from Ag-Grid case insensitive.
     *
     * @param {valueA}   string
     * @param {valueB}   string
     *
     * @return number
     */
    insensitiveComparator(valueA: string, valueB: string): number {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }

    /**
     * Call all the callbacks for a certain event.  The Api and ColumnApi are passed in as they may be null
     * at instantiation.
     *
     * @param {CallbackEventTypes} dispatchEvent
     * @param {GridApi}            api
     * @param {ColumnApi}          columnApi
     */
    dispatchEvent(dispatchEvent: CallbackEventTypes, api: GridApi, columnApi: ColumnApi): void {
        // loop through all callbacks, fire the callback that matches the event
        this.props.callbacks.forEach(({ event, callable }): void => {
            if (dispatchEvent === event) {
                callable({ id: this.props.id, api, columnApi, customOptions: this.customOptions });
            }
        });
    }

    /**
     * Call the 'handleSave' prop and pass in gridApi and customOptions as they may be needed for saving.
     *
     * @return void
     */
    onSave(): void {
        this.selectedRows = {};

        // make sure a handleSave prop was passed
        if (this.props.handleSave === undefined) {
            return;
        }

        // pass the api and customOptions to the handle for any custom save operation
        this.props.handleSave({
            api: this.api,
            customOptions: this.customOptions,
            changed: this.state.selected
        });
    }

    /**
     * Handles Print Function
     * @return void
     */
    handlePrint(): void {
        // Using Vanilla TS to Accomplish in Order to Match Legacy Datatable (w/ IE11) Print Functionality
        const eGridDiv: HTMLDivElement | null = document.querySelector(`#${this.props.id}-container`);
        const eHead: HTMLHeadElement | null = document.querySelector('head');

        // Prepare Grid for Print
        this.api?.setDomLayout('print');
        this.columnApi?.autoSizeAllColumns();

        // Setup Print Preview Page
        const gridHtml: string | undefined = eGridDiv?.innerHTML.replace('ag-theme-alpine', 'agPrintView__wrapper');
        const previewHtml: string = `
            <html>
                <head>${eHead?.innerHTML}</head>
                <body class="agPrintView">
                    <h1>${this.props.name}</h1>
                    ${gridHtml}
                </body>
            </html>
        `;

        // Open Print Preview Window
        const previewWindow: Window | null = window.open('', '_blank');
        previewWindow?.document.write(previewHtml);

        // Set Timeout to Ensure Print Dialog Shows (Required for Older Browsers)
        setTimeout(() => {
            // IE 11 Fix - Have to close document
            previewWindow?.document.close();
            previewWindow?.print();
            this.api?.setDomLayout('null');
        }, 1500);
    }

    /**
     * Handles Reset Columns Function
     * @return void
     */
    handleResetColumns(): void {
        if (this.agGrid.current !== null) {
            const columnApi: ColumnApi = this.agGrid.current.columnApi;
            if (this.api !== null) {
                this.api.sizeColumnsToFit();
                if (this.isDatatableSessionEnabled()) {
                    saveDatatableState(this.props.id, this.props.app, columnApi);
                }
            }
        }
    }

    /**
     * Handles Restore Columns Function
     * @return void
     */
    handleRestoreColumns(): void {
        if (this.api !== null && this.columnApi !== null) {
            this.columnApi.resetColumnState();

            if (this.isDatatableSessionEnabled()) {
                saveDatatableState(this.props.id, this.props.app, this.columnApi);
            }
        }
    }

    /**
     * Handles Export All Function
     * @return void
     */
    handleExportAll(): string | void {
        return this.handleExport('all');
    }

    /**
     * Handles Export View Function
     * @return void
     */
    handleExportView(): string | void {
        return this.handleExport('view');
    }

    /**
     * Constructs and sends an Export request
     * @param type
     */
    handleExport(type: ExportType): void {
        const dataSourceUrl: string | undefined = this.serverSideDataSource?.getCurrentUri();
        if (this.props.authUserId === undefined || dataSourceUrl === undefined || this.props.app === undefined) {
            return;
        }

        const uuid: string = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
        const props: ExportRequestProps = {
            type: type,
            id: this.props.id,
            uuid: uuid,
            app: this.props.app,
            uri: dataSourceUrl,
            gridOptions: this.gridOptions,
            authUserId: this.props.authUserId,
            addNotification: this.props.addNotification
        };

        // Include externalFilter filterBy field if set
        if (this.props.externalFilter) {
            props.externalFilterField = this.props.filterBy;
        }

        sendExportRequest(props);
    }

    /**
     * Handles change of value on external filter
     * @param {string} newValue
     * @return void
     */
    externalFilterChanged(newValue: FilterTabsMap): void {
        const dataSource: DataSourceType<TData> = this.getDataSource();
        const app: string = this.props.app ?? noApp;
        const lastRow: number | null = getSessionRow(this.props.id);

        this.filterType = newValue;

        if (this.api !== null) {
            this.serverSideDataSource = new ServerSideDataSource<TData>(
                dataSource,
                app,
                this.api,
                this.customOptions,
                lastRow,
                this.filterType,
                this.props.filterBy
            );
            this.api.setServerSideDatasource(this.serverSideDataSource);
        }
    }

    /**
     * Checks if an external filter is present, then checks the value based on filterType.
     * Will only run doesExternalFilterPass if returns true
     * @return boolean
     */
    isExternalFilterPresent(): boolean {
        return this.filterType?.name !== this.props.tableDefault;
    }

    /**
     * Checks if an external filter passes, by comparing the node values to the filterType value
     * @return boolean
     */
    doesExternalFilterPass(node: RowNode): boolean {
        let currentFilter: string | FilterTabsMap | undefined = this.filterType;
        forEach(this.props.filterTabs, (filterTab) => {
            if (filterTab === this.filterType) {
                currentFilter = filterTab;
            }
        });
        return node.data[`${this.props.filterBy}`] === currentFilter?.name;
    }

    /**
     * Notifies the parent component when the grid is ready after mounting
     *
     * @return void
     */
    notifyParentGridIsReady(): void {
        if (this.props.gridReady !== undefined) {
            this.props.gridReady(this.api);
        }
    }

    /**
     * Handles Main column menu items, we replace resetColumns with our own that works as intended
     *
     * @param {GetMainMenuItemsParams} params
     *
     * @return (string | MenuItemDef)[]
     */
    getMainMenuItems = (params: GetMainMenuItemsParams): (string | MenuItemDef)[] => {
        const lastDefaultItem: string[] = params.defaultItems.slice(-1);
        if (lastDefaultItem[0] === 'resetColumns') {
            // Remove non functional resetColumns from AgGrid to replace with ours
            params.defaultItems.pop();
        } else {
            // Return early if resetColumns isn't present
            return params.defaultItems;
        }
        const newMenuItems: any = params.defaultItems.slice(0);
        newMenuItems.push({
            name: 'Reset Columns',
            action: this.handleResetColumns
        });
        return newMenuItems;
    };

    /**
     * Determines if saving the datatable state is enabled, which is enabled by default
     *
     * @returns boolean
     */
    isDatatableSessionEnabled(): boolean {
        return this.props.enableDatatableState !== undefined ? this.props.enableDatatableState : true;
    }

    /**
     * Handles RowDragEnd, gets the rowNode data and passes it to the app to handle
     *
     * @returns void
     */
    handleRowDragEnd(): void {
        const rowNodes: Record<string, unknown>[] = [];
        if (this.api !== null) {
            this.api.forEachNode((rowNode) => {
                rowNodes.push({ rowIndex: rowNode.rowIndex, data: rowNode.data });
            });
        }
        if (this.props.handleReorder !== undefined) {
            this.props.handleReorder(rowNodes);
        }
    }

    /**
     * @return {React.ReactNode}
     */
    render(): React.ReactNode {
        let className: string = 'grid__default';

        // Switch case to determine Datatable classname based on type
        switch (this.props.type) {
            case 'full':
                className = 'grid__wrapper';
                break;
            case 'full-form':
                className = 'grid__wrapper__full-form';
                break;
            case 'form':
                className = 'grid__wrapper__form';
                break;
            case 'filter':
                className = 'grid__wrapper__filter';
                break;
        }

        /**
         * Get changed rows
         *
         * @param event RowSelectedEvent
         *
         * @return {void}
         */
        const onRowSelected = (event: RowSelectedEvent): void => {
            // Get the selected rows from grid

            if (event.node.id === undefined) {
                return;
            }

            const id = event.node.id;
            const newObj: ChangedItems = { id: id, selected: event.node.isSelected() === true };
            this.setState((prevState) => ({ ...prevState, selected: { ...this.state.selected, [id]: newObj } }));
        };

        return (
            <React.Fragment>
                {!this.state.isStateReady && <Loader animation='border' />}

                <div
                    id={`${this.props.id}-container`}
                    className={className}
                    style={!this.state.isStateReady ? { display: 'none' } : {}}
                >
                    <div className='ag-theme-alpine'>
                        {this.props.externalFilter && (
                            <TableFilter
                                defaultActive={this.defaultFilter}
                                tabDetails={this.filterTabs}
                                handleSelect={this.externalFilterChanged}
                                navBaseUrl={window.location.pathname}
                            />
                        )}
                        <AgGridReact
                            {...this.gridOptions}
                            isExternalFilterPresent={this.isExternalFilterPresent}
                            doesExternalFilterPass={this.doesExternalFilterPass}
                            getMainMenuItems={this.getMainMenuItems}
                            onRowDragEnd={this.handleRowDragEnd}
                            onRowSelected={onRowSelected}
                            rowDragManaged={this.props.rowDragManaged ? this.props.rowDragManaged : false}
                            animateRows={this.props.animateRows ? this.props.animateRows : false}
                            tooltipShowDelay={this.gridOptions.tooltipShowDelay}
                            frameworkComponents={this.gridOptions.frameworkComponents}
                            ref={this.agGrid}
                        />
                    </div>
                </div>
                {this.props.handleSave && (
                    <div className='grid__wrapper__form-bottom-save d-flex'>
                        <SaveButton
                            id={`${this.props.id}-save-btn`}
                            variant='primary'
                            className={this.props.hideSave ? 'd-none' : ''}
                            disabled={this.props.disableSave}
                            onClick={() => this.onSave()}
                        />
                        {this.props.children && <ButtonToolbar className='ml-1'>{this.props.children}</ButtonToolbar>}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export { Datatable, DatatableProps, HandleSaveParams };
