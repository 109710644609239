import React, { Component, ReactElement, Fragment } from 'react';
import { ColDef, Column, ColumnApi, GridApi } from '@ag-grid-community/core';
import { User } from 'lib/types';

interface Props {
    agGridReact: {
        eGridDiv: HTMLDivElement;
    };
    api: GridApi;
    colDef?: ColDef;
    column?: Column;
    columnApi?: ColumnApi;
    data?: User;
    location?: string;
    rowIndex: number;
    value?: string;
}

/**
 * Custom Tooltip component that that will display a tooltip over a cell if column conditions are met
 */
class CustomTooltip extends Component<Props> {
    /**
     * Get React Container Class for AgGrid Popover tooltip functionality
     *
     * @return {string[]}
     */
    getReactContainerClasses(): string[] {
        return ['custom-tooltip'];
    }

    /**
     * @return {React.ReactNode}
     */
    render(): React.ReactNode {
        let smallColumn: boolean = false;
        let tooltip: ReactElement;
        const columnWidth: number | undefined = this.props.column?.getActualWidth();
        if (columnWidth !== undefined && columnWidth < 85) {
            smallColumn = true;
        }

        // only set the tooltip text if the width is smaller than certain column length
        // only display a tooltip if there is tooltip value
        if (!smallColumn) {
            return null;
        } else {
            tooltip = (
                <div className='custom-tooltip'>
                    <p>
                        <span>{this.props.value}</span>
                    </p>
                </div>
            );
        }

        return <Fragment>{tooltip}</Fragment>;
    }
}

export { CustomTooltip };
